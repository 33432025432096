const TYPE_DESIGNATION = 'Type / Designation';
const DESCRIPTION = 'Description';
const IMAGE = 'Image';
const PRODUCT_TYPE = 'Product Type';
const MODEL = 'Model (Manufacturer Series Name)';
const VOLTAGE = 'Voltage';
const MANUFACTURER = 'Manufacturer';
const MANUFACTURER_DESCRIPTION = 'Manufacturer Description';
const VERIFIED = 'isVerified';
const CHILD_VERIFIED = 'childVerified';
const VERIFIED_PAGE_ID = 'verifiedPageId';
const VERIFIED_PAGE_NAME = 'verifiedPageName';
const LIGHT_SOURCE_TYPE = 'Light Source Type';
const ENVIRONMENT = 'Environment';
const MOUNTING = 'Mounting';
const DIMMING_PROTOCOL = 'Dimming Protocol';
const AGENT_SUPPLIER = 'Agent/Supplier';
const LOCATION = 'Location';
const TAG = 'Tag';
const BUDGET_PRICE = 'Budget Price';
const QUANTITY = 'Quantity';
const TOTAL_COST = 'Total Cost';
const VERSION = 'Version';
const SESSION = 'Session';
const ID = 'id';
const DEFAULT_FORCE_UPDATE = 'DEFAULT_FORCE_UPDATE';
const SORTING_COEFFICIENT = 'sortingCoefficient'; // sorting option only, not a header
const TIPS_AND_TRICKS = 'Tips and Tricks';
const PRODUCT_CODE = 'Product Code';
const PRODUCT_NOTES = 'Product Notes';
const PROJECT_NOTES = 'Notes - Project Specific';
const PRODUCT_REFERENCE = 'Product Reference';
const WEBSITE_LINK = 'Website Link';
const SPEC_SHEET_LINK = 'Specification Sheet Link';
const CHOOSE_COLLECTION = 'Choose Collection';

export const SCRAPING_HEADERS = [
  BUDGET_PRICE,
  IMAGE,
  MODEL,
  MANUFACTURER,
  MANUFACTURER_DESCRIPTION,
  PRODUCT_CODE,
  SPEC_SHEET_LINK,
];

/**
 * https://app.asana.com/0/1205146388025688/1207898211310102
 * 'Manufacturer' and 'Model (Manufacturer Series Name)'
 *
 * The 'requiredFields' on the schema should be checked first before we use this.
 */
export const DEFAULT_PRODUCT_REQUIRED_FIELDS = [
  'B', 'AO',
];

/**
 * @deprecated
 */
const SHOP_DRAWINGS = 'Shop Drawings';
/**
 * @deprecated
 */
const DRAWING_UPLOADED_DATE = 'Drawing Uploaded Date';
const DISABLED_IN_SETTINGS = [TYPE_DESIGNATION];
export default {
  TYPE_DESIGNATION,
  DESCRIPTION,
  IMAGE,
  PRODUCT_TYPE,
  MODEL,
  VOLTAGE,
  MANUFACTURER,
  MANUFACTURER_DESCRIPTION,
  LIGHT_SOURCE_TYPE,
  ENVIRONMENT,
  MOUNTING,
  DIMMING_PROTOCOL,
  AGENT_SUPPLIER,
  LOCATION,
  TAG,
  SORTING_COEFFICIENT,
  BUDGET_PRICE,
  QUANTITY,
  TOTAL_COST,
  SHOP_DRAWINGS,
  DRAWING_UPLOADED_DATE,
  DISABLED_IN_SETTINGS,
  VERSION,
  SESSION,
  ID,
  DEFAULT_FORCE_UPDATE,
  VERIFIED,
  CHILD_VERIFIED,
  VERIFIED_PAGE_ID,
  VERIFIED_PAGE_NAME,
  TIPS_AND_TRICKS,
  PRODUCT_CODE,
  PRODUCT_NOTES,
  PROJECT_NOTES,
  PRODUCT_REFERENCE,
  WEBSITE_LINK,
  CHOOSE_COLLECTION,
};