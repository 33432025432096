<template>
  <div
    v-if="showAIChat"
    data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"
    data-airops-init="true" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppAIChat',
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
  },
  created() {
    if (this.showAIChat) this.loadExternalResources();
  },
  methods: {
    loadExternalResources() {
      // Load the external script file
      const script = document.createElement('script');
      script.src = 'https://assets.airops.com/widget/index.js';
      script.async = true;

      // update css styles after script is loaded
      script.onload = () => {
        this.updateStyles();
      };

      document.head.appendChild(script);
    },
    updateStyles() {
      this.$nextTick();
      const host = document.querySelectorAll('[data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"]')[0];

      // get the shadow element
      const shadowDom = host.shadowRoot;

      const shadowStyle = document.createElement('style');
      shadowStyle.textContent = `
        .ao-h-\\[740px\\] {
          height: 580px;
        }
        .ao-bg-primary, .ao-bg-user-message {
          background: var(--ao-primary);
        }
        .ao-bottom-12 {
          bottom: calc(50% - 50px);
          right: 12px;
          @media (min-width: 992px) {
            bottom: 20px;
            right: 20px;
          }
        }
        .ao-h-12.ao-w-12 {
          height: 45px;
          width: 45px;

          @media (min-width: 992px) {
            height: 65px;
            width: 65px;
          }

          svg {
            height: 20px;
            width: 20px;

            @media (min-width: 992px) {
              height: 30px;
              width: 30px;
            }
          }
        }
        .ao-absolute.ao-bottom-16.ao-right-0 {
          position: fixed;
          bottom: 6.5rem;
          right: 4rem;

          @media (min-width: 992px) {
            position: absolute;
            bottom: 4.5rem;
            right: 0;
          }
        }
      `;

      // add custom styles to it
      shadowDom.appendChild(shadowStyle);
    },
  },
};
</script>

<style lang="scss">
div[data-airops-widget] {
  --ao-primary: var(--v-black-base);
  --ao-user-message: var(--v-black-base);
  z-index: 99;
}
</style>